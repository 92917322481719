<template>
  <div class="row">
    <div class="flex xs12">
      <va-file-upload
        dropzone
        v-model="material"
      />
    </div>
    <div
      class="flex xs12 send-form"
      v-if="!wizard"
    >
      <va-button
        color="primary"
        :disabled="loading || haveErrors"
        @click.prevent="submit"
      >
        <text-loading
          :loading="loading"
          icon="fa fa-save"
        >{{ $t('layout.form.save') }}</text-loading>
      </va-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'training-documents',
  props: {
    training: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    wizard: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },
  data () {
    return {
      material: [],
    }
  },
  computed: {
    haveErrors () {
      return this.material.length === 0
    },
  },
  watch: {
    material (val) {
      this.filterMaterial(val)
    },
  },
  methods: {
    filterMaterial (material) {
      const maxFileSize = 58720256
      const fileTypes = ['application/pdf']
      const currentMaterial = material.filter(g => {
        const size = g.size <= maxFileSize
        const type = fileTypes.findIndex(t => t === g.type) !== -1
        return size && type
      })
      if (currentMaterial.length !== material.length) {
        const types = '.pdf'
        const size = '56MB'
        this.showToast(this.$t('layout.images.not_allowed', { types, size }), {
          icon: 'fa-times',
          duration: 3000,
          position: 'top-right',
        })
        this.material = currentMaterial.slice(0)
      }
    },
    submit () {
      this.$emit('submit', this.material)
    },
  },
}
</script>
  <style scoped>
  .send-form {
    margin-top: 10px;
  }
  </style>
